// Accordion fuction
// - Open default

// Open default - Grobal style
.accordion-action {
  .ac-input {
    display: none;

    &:checked {
      + .ac-action {
        transition-delay: 0.1s;
        transition: 0.1s;
      }

      ~ .panel {
        height: 100%;
        -webkit-transition: max-height 0.5s ease-in-out;
        -moz-transition: max-height 0.5s ease-in-out;
        -o-transition: max-height 0.5s ease-in-out;
        -ms-transition: max-height 0.5s ease-in-out;
        transition: max-height 0.5s ease-in-out;
      }
    }
  }

  .ac-action {
    position: relative;
    display: block;
    cursor: pointer;
  }

  .panel {
    overflow: hidden;
    height: 0;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    -ms-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
  }
}

// Open default - Brand style
.accordion-action {
  @include size(100%, auto);

  .ac-item {
    background-color: transparent;
  }

  .ac-action {
    padding: 10px 32px;
    @include size(100%, auto);
    font-weight: 700;

    span {
      padding-right: 44px;
      @include background-img(24px 24px, 100% -6px);
      background-image: url(/assets/ic/component/arrow-down-line-blue.svg);
    }
  }

  .ac-input {
    &:checked {
      + .ac-action {
        background-color: $brand-blue-100;
        border-radius: 8px 8px 0 0;
        padding-top: 24px;
        margin-top: 10px;

        span {
          background-image: url(/assets/ic/component/arrow-up-line-blue.svg);
        }
      }

      ~ .panel {
        background-color: $brand-blue-100;
        padding: 0 32px 24px 32px;
        border-radius: 0 0 8px 8px;
        margin-bottom: 10px;
      }
    }
  }
}
