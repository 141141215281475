// Download
// - Home
// - Download

.app-group {
  margin-top: 40px;

  @include respond-to('desktop') {
    display: flex;
  }

  .row {
    @include size(100%);

    + .row {
      margin-top: 80px;

      @include respond-to('desktop') {
        margin-top: 0;
      }
    }

    > * {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    > img {
      @include size(120px);
      margin-bottom: 24px;

      @include respond-to('desktop') {
        @include size(140px);
      }
    }

    .label {
      margin-bottom: 24px;
      color: $brand-gray;
    }

    .btn {
      width: 240px;
      border-radius: 100px;

      &.btn-primary {
        background-color: $brand-black;
      }

      &.btn-outline {
        border: 1px solid $brand-black;
        color: $brand-black;
      }

      + .btn {
        margin-top: 16px;
      }
    }
  }
}

.container.download {
  h1 + div {
    width: 740px;
    margin-left: auto;
    margin-right: auto;
  }

  .label {
    font-size: 20px;
  }

  .app-group {
    .row:first-child {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 221px;
        right: 0;
        top: 71.5px;
        // border-left: dashed 1px #93aeee;
        z-index: 10;
        background: linear-gradient(#fff 7px, transparent 1%) center, #93aeee;
        background-size: 1px 12px;
      }
    }
  }
}
