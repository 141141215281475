// @import '~antd/dist/antd.compact.css';
@import '~simplebar/dist/simplebar.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/cleanui/styles/style'; // Clean UI styles
// @import 'components/kit/core/style'; // Clean UI KIT styles
@import 'scss/main'; // 디자인 적용.

@import 'gql/ninegqlUI/style';

body {
  position: relative;
  min-height: 100vh;

  #image-picker {
    width: 1px;
    height: 1px;
    left: 0;
    bottom: 0;
    text-indent: -1000px;
    position: absolute;
  }

  &[scroll='false'] {
    overflow: hidden;
  }
}

div#root,
:root {
  position: relative;
  min-height: 100vh;

  --kit-color-primary: #4b7cf3;
  --kit-font-family: 'Lato', 'Mukta', sans-serif;
}

[data-kit-theme='default'] .ant-radio {
  vertical-align: middle;
}

.cui__layout__grayBackground {
  background-color: transparent;
}

.hidden {
  display: none;
}

// .jumbotron .container .col div.btn-group:after {
//   content: $PUBLIC_URL;
// }

.main-layout {
  position: relative;
  min-height: 100vh;

  // padding-top: 96px;

  // padding-bottom: 681px;
  // @include respond-to('desktop') {
  //   padding-bottom: 334px;
  // }

  // .navbar {
  //   position: absolute;
  //   top: 0;

  //   &.is-scrolled {
  //     position: fixed;
  //   }
  // }

  .main-content {
    z-index: 0;

    padding-top: 60px;

    @include respond-to('desktop') {
      padding-top: 90px;
    }
  }

  .navbar + .main-content {
    min-height: calc(100vh - 681px);

    font-family: AvenirNext, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #3a4555;

    @media (min-width: 900px) {
      min-height: calc(100vh - 334px);
    }
  }

  // .footer {
  //   position: absolute;
  //   bottom: 0;
  // }
}

.default-css-a {
  color: #00e;
  text-decoration: underline;

  &:hover {
    color: #00e;
    text-decoration: underline;
  }

  &:visited {
    color: #551a8b;
    text-decoration: underline;
  }

  &:active {
    color: #e00;
    text-decoration: underline;
  }
}

.empty-layout {
  height: 100vh;
}

li.divider {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 0;
  width: 100%;
  border-bottom: 1px solid #eaf0ff;
}

.navbar {
  position: absolute;
  z-index: 1;
}

@include respond-to('desktop') {
  .slide-down {
    animation-duration: 0.8s;
    animation-name: slidedown;
  }

  .slide-down-hide {
    animation-duration: 0.8s;
    animation-name: slidedown-hide;
  }
}

@keyframes slidedown {
  from {
    opacity: 1;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slidedown-hide {
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 1;
    transform: translateY(-100%);
  }
}

.simplebar-scrollbar:before {
  background: lightgray;
}
