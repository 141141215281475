// 홈

// 상단 장식
.main-deco-top {
  position: relative;
  height: 0;
  max-width: $brand-max-width;
  margin-left: auto;
  margin-right: auto;

  .main-deco-line {
    display: none;

    @include respond-to('desktop') {
      display: block;
      position: absolute;
      top: -100px;
      z-index: $z-index-main-deco;
      left: 260px;
    }
  }
}

// 메인 공통 라벨
.main-label {
  position: relative;
  color: $brand-blue;
  font-weight: 700;
  margin-bottom: 16px;
  font-family: 'Lato', sans-serif;

  img {
    margin-top: -4px;
    margin-right: 16px;
  }

  .download-deco {
    position: absolute;
    top: -39px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .pricing-deco {
    position: absolute;
    top: -12px;
    left: 0;
    right: 162px;
    bottom: 0;
    margin: auto;
  }
}

// 홈 메인 이미지
.main-gallery {
  // background-color: $brand-black;
  @include size(100%, auto);
  overflow: hidden;

  .container {
    position: relative;
    max-width: $brand-max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 24px;

    @include respond-to('tablet') {
      padding: 80px 24px;
    }
  }

  .row {
    &.title {
      // color: $white;
      padding-bottom: 32px;

      @include respond-to('desktop') {
        // padding-top: 148px;
        // padding-bottom: 210px;
        // max-width: 490px;
        padding-top: 132px;
        padding-bottom: 163px;
        max-width: 460px;
      }

      .epic-bubble {
        background-color: $brand-blue;
        color: $white;
        padding: 8px 16px;
        margin-bottom: 12px;
        max-width: fit-content;
        border-radius: 8px;

        @include respond-to('desktop') {
          // margin-bottom: 24px;
          margin-bottom: 20px;
          border-radius: 50px;
        }

        img {
          @include size(20px);
          margin-top: -4px;
          margin-left: 2px;
        }
      }

      h1 {
        color: #0e1621;
      }

      .sub-text {
        margin-top: 12px;
        font-size: $block-sub-font-size;
        line-height: 1.5;

        @include respond-to('desktop') {
          // margin-top: 24px;
          margin-top: 20px;
        }
      }
    }

    &.client {
      color: $brand-gray-light;

      .contents-group {
        @include respond-to('desktop') {
          @include flex;
          @include size(100%, 67px);
        }

        .client-logo-list {
          position: relative;
          @include size(100%, 52px);
          overflow: hidden;
          margin-top: 16px;
          margin-bottom: 32px;

          @include respond-to('desktop') {
            @include size(655px, 52px);
            margin-right: 116px;
            margin-top: auto;
            margin-bottom: auto;
          }

          .client-logo-flow {
            @include auto-flow(60s);
            @include flex;
            -webkit-box-align: stretch;
            align-items: stretch;
            width: 2918px;

            span {
              float: left;
              width: 2918px;
              margin-right: 46px;
            }

            img {
              object-fit: contain;
            }
          }

          .gutter-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            height: 100%;
            // background-image: linear-gradient(to right, rgb(14, 22, 33), rgba(14, 22, 33, 0) 99%);
            background-image: linear-gradient(
              to right,
              rgb(255, 255, 255),
              rgba(255, 255, 255, 0) 99%
            );

            &.end {
              left: unset;
              right: 0;
              // background-image: linear-gradient(to left, rgb(14, 22, 33), rgba(14, 22, 33, 0) 99%);
              background-image: linear-gradient(
                to left,
                rgb(255, 255, 255),
                rgba(255, 255, 255, 0) 99%
              );
            }
          }
        }

        .download {
          display: block;
          @include size(205px, 67px);
          line-height: 67px;
          border-radius: 8px;
          text-align: center;
          // background-color: $white;
          // color: $brand-black;
          background-color: $brand-blue;
          color: $white;
          z-index: 2;
          padding-top: 1px;

          img {
            @include size(24px);
            margin-top: -4px;
          }
        }
      }
    }
  }

  .main-ui {
    display: none;

    @include respond-to('desktop') {
      display: block;
      position: absolute;
      // @include size(828px, auto);
      @include size(540px, auto);
      top: 113px;
      // right: -350px;
      right: 0;
      z-index: 2;
    }
  }

  .main-deco-under-line {
    display: none;

    @include respond-to('desktop') {
      display: block;
      position: absolute;
      bottom: 0;
      z-index: 1;
      right: -120px;
    }
  }
}

// 주요기능 설명
.main-features {
  grid-template-columns: repeat(1, 2fr);
  margin-bottom: -20px;

  @include respond-to('desktop') {
    @include flex; // ie10 이하 버전 지원
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    // margin-bottom: -80px;
    margin-bottom: -300px;
  }

  .row {
    &.image {
      position: relative;
      z-index: 5;
      width: 100%;
      overflow: hidden;
      order: 1;

      @include respond-to('desktop') {
        grid-column: span 6;
        overflow: visible;
      }

      // img {
      //   @include size(100%, auto);
      //   max-width: 420px;

      //   @include respond-to('desktop') {
      //     @include size(120%, auto);
      //     max-width: unset;
      //     margin-left: -75px;
      //     margin-right: 0;
      //   }
      // }

      img {
        @include size(100%, auto);
        padding: 10px;
        max-width: 420px;

        @include respond-to('desktop') {
          // @include size(120%, auto);
          @include size(702px, 702px);
          max-width: unset;
          margin-left: -75px;
          margin-right: 0;
          padding: 130px;
          // background: red;

          &.padding160 {
            padding: 160px;
          }
        }
      }
    }

    &.data {
      position: relative;
      z-index: 6;
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
      order: 2;

      @include respond-to('desktop') {
        //width: 40%;
        grid-column: span 4;
      }

      color: $brand-gray;

      > * {
        + * {
          margin-top: 16px;
        }
      }

      a {
        display: block;
        color: $brand-blue;

        img {
          margin-left: 4px;
        }
      }
    }
  }

  &.reverse-order {
    .row {
      &.image {
        order: 1;

        @include respond-to('desktop') {
          order: 2;
        }

        img {
          @include respond-to('desktop') {
            margin-left: -40px;
          }
        }
      }

      &.data {
        order: 2;

        @include respond-to('desktop') {
          order: 1;
        }
      }
    }
  }

  &:nth-child(1) {
    position: relative;
    z-index: 4;

    // .data h2 + div {
    //   width: 370px;
    // }
  }

  &:nth-child(2) {
    position: relative;
    z-index: 3;

    // .data h2 + div {
    //   width: 355px;
    // }
  }

  &:nth-child(3) {
    position: relative;
    z-index: 2;

    // .data {
    //   h2 {
    //     width: 290px;
    //   }

    //   h2 + div {
    //     width: 290px;
    //   }
    // }
  }

  &:nth-child(4) {
    position: relative;
    z-index: 1;

    // .data {
    //   h2 {
    //     width: 300px;
    //   }

    //   h2 + div {
    //     width: 300px;
    //   }
    // }
  }
}

.main-gallery + .container {
  .list-group:nth-child(1) {
    .list-group-item:nth-child(2) {
      h3 {
        padding-right: 60px;
      }

      .info {
        padding-right: 15px;
      }
    }
  }

  .list-group:nth-child(2) {
    .list-group-item:nth-child(2) {
      h3 {
        padding-right: 100px;
      }
    }
  }

  .our-client {
    h1 {
      color: #0e1621;
    }
  }

  .pricing {
    margin-top: 80px;
    margin-bottom: 80px;

    .main-label {
      margin-bottom: 38px;
    }
  }

  .download {
    margin-top: 140px;
    margin-bottom: 234px;
  }
}
