// Jumbotron
// - Center
// - Coloum

.jumbotron {
  @include size(100%);

  .container {
    max-width: $brand-max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 80px 24px 0 24px;
    font-size: $block-sub-font-size;

    @include respond-to('desktop') {
      padding: 120px 24px 0 24px;
    }

    &.bottom80 {
      padding: 80px 24px 40px 24px;

      @include respond-to('desktop') {
        padding: 120px 24px 80px 24px;
      }
    }

    h1 {
      margin-bottom: 16px;
    }

    .col h1 {
      margin-bottom: 32px;
    }

    .btn-group {
      margin-top: 32px;

      .btn {
        display: block;
        border-radius: 65px;
        width: 210px;
        float: left;

        @include respond-to('tablet') {
          display: inline-block;
        }

        + .btn {
          margin-left: 0;
          // margin-top: 16px;
          float: right;

          @include respond-to('tablet') {
            margin-left: 16px;
            margin-top: 0;
          }
        }
      }
    }
  }

  &.center {
    // background-color: $brand-black;
    // color: $white;
    background-color: $white;
    color: #0e1621;
    text-align: center;

    .sub-title {
      color: $brand-blue;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.4;
    }

    h1 + div {
      color: #3a4555;
      line-height: 1.5;
      max-width: 100%;
    }

    .image {
      margin-top: 40px;
    }

    .btn-group {
      @include size(fit-content);
      margin-left: auto;
      margin-right: auto;

      .btn-primary {
        background-color: $white;
        color: $brand-black;
      }

      .btn-outline {
        border: 1px solid $white;
        color: $white;
      }
    }
  }

  &.coloum {
    .container {
      display: block;

      @include respond-to('desktop') {
        @include flex;
      }
    }

    .col {
      @include size(100%);

      &.image {
        position: relative;
        @include size(100%, 360px);
        max-width: 520px;
        margin: auto;
        margin-top: 40px;
        overflow: hidden;

        @include respond-to('desktop') {
          @include size(100%, 480px);
          margin-top: 0;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          @include size(100%, auto);
          max-width: 430px;

          @include respond-to('desktop') {
            max-width: none;
          }
        }
      }

      &.text {
        @include size(100%, auto);
        margin: auto;

        @include respond-to('desktop') {
          max-width: 450px;
          margin-right: 40px;
        }
      }
    }
  }
}
