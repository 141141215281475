// 약관

.terms-wrap {
  h2 {
    margin-bottom: 24px;
  }

  .terms-group {
    margin-bottom: 40px;

    h3 {
      margin-bottom: 8px;
    }

    h4 {
      margin-bottom: 8px;
    }

    p {
      margin-bottom: 8px;
    }

    a {
      display: inline;
      color: $brand-blue;
      font-weight: 700;
    }

    ol, ul, dl {
      padding: revert;
      list-style: revert;
      margin-bottom: 8px;
    }
  }
}
