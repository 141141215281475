@import 'components/mixins.scss';

@import 'scss/mixins';

.breadcrumbs {
  padding: 36px 35px 0;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #0e1621;
}

@include respond-to('mobile') {
  .breadcrumbs {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 50px;
    background: #f2f4f8;
    z-index: 10;
    padding: 0 56px;
    line-height: 50px;
    border-bottom: 1px solid lightgray;
  }

  .breadcrumbs + main {
    padding-top: 50px;
  }

  .breadcrumbs + main + footer:global(.ant-layout-footer) {
    padding-bottom: 1em !important;
  }
}

.current {
  color: $black;
}

.arrow {
  display: inline-block;
  margin: 0 0;
  &:after {
    display: inline-block;
    // content: '·';
    color: $gray-5;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .breadcrumbs {
    color: $dark-gray-1;
  }

  .current {
    color: $white;
  }

  .arrow {
    &:after {
      color: $dark-gray-1;
    }
  }
}
