// 내용이 흐르는 애니메이션

@mixin auto-flow($time) {
  white-space: nowrap;
  animation: marquee $time linear infinite;
  -webkit-animation: marquee $time linear infinite;
  -webkit-animation-name: marquee;
  -webkit-animation-timing-function: linear;
  -webkit-animation-duration: $time;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: marquee;
  -moz-animation-timing-function: linear;
  -moz-animation-duration: $time;
  -moz-animation-iteration-count: infinite;
  -ms-animation-name: marquee;
  -ms-animation-timing-function: linear;
  -ms-animation-duration: $time;
  -ms-animation-iteration-count: infinite;
  -o-animation-name: marquee;
  -o-animation-timing-function: linear;
  -o-animation-duration: $time;
  -o-animation-iteration-count: infinite;
  animation-name: marquee;
  animation-timing-function: linear;
  animation-duration: $time;
  animation-iteration-count: infinite;
}

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translate(0%);
  }

  99%,
  to {
    -webkit-transform: translate(-100%);
  }
}

@-moz-keyframes marquee {
  from {
    -moz-transform: translate(0%);
  }

  99%,
  to {
    -moz-transform: translate(-100%);
  }
}

@-ms-keyframes marquee {
  from {
    -ms-transform: translate(0%);
  }

  99%,
  to {
    -ms-transform: translate(-100%);
  }
}

@-o-keyframes marquee {
  from {
    -o-transform: translate(0%);
  }

  99%,
  to {
    -o-transform: translate(-100%);
  }
}

@keyframes marquee {
  from {
    transform: translate(0%);
  }

  99%,
  to {
    transform: translate(-100%);
  }
}