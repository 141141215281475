@import 'components/mixins.scss';

.container {
  section:global(.ant-layout) {
    padding: 0;
    width: 100%;

    > section:global(.ant-layout) {
      padding: 0;
    }
  }

  :global(.section) {
    padding: 0;
  }
}
