// Modal

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-modal;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  background-color: #161616e8;
}

.modal-wrap {
  position: absolute;
  margin: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  width: 90%;
  height: fit-content;
  overflow-y: auto;
  max-width: 397px;
  max-height: 90%;
  border-radius: 8px;

  .modal-header {
    position: fixed;
    background-color: $white;
    text-align: center;
    @include size(90%, auto);
    max-width: 397px;
    padding-top: 20px;
    border-radius: 8px 8px 0 0;

    .title {
      margin-bottom: 24px;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .modal-content {
    padding-top: 80px;
    padding: 72px 16px 20px;

    @include respond-to('desktop') {
      padding: 72px 24px 20px;
    }

    .form-label {
      text-align: left;
    }

    .link {
      color: $brand-blue;

      &:hover {
        cursor: pointer;
      }
    }

    .info {
      color: $brand-gray-light;
      font-size: $font-size-small;
    }
  }
}
