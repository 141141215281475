// Pricing

h1 {
  + .price-group {
    margin-top: 40px;
  }
}

.price-group {
  display: block;

  @include respond-to('desktop') {
    @include flex;
  }

  .row {
    @include flex;
    align-items: flex-start;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    @include size(100%, auto);
    text-align: left;
    border: 1px solid $brand-blue;
    padding: 40px;
    border-radius: 16px;

    + .row {
      margin-top: 32px;

      @include respond-to('desktop') {
        margin-left: 32px;
        margin-top: 0;
      }
    }

    > * {
      + * {
        margin-top: 16px;
      }
    }

    .price {
      @include flex;
      font-size: 32px;
      font-weight: 700;

      span {
        display: inline-block;
        font-size: $font-size-small;
        margin-left: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: $brand-blue;
        margin-top: 10px;
      }
    }

    .btn {
      border-radius: 100px;
    }

    .price-features {
      @include flex-fill;
      margin-bottom: 4px;
      font-size: 14px;

      li {
        @include background-img(24px 24px, 0 0);
        background-image: url(/assets/ic/component/check-circle-blue.svg);
        padding-left: 32px;
        margin-top: 12px;
      }
    }

    &.epic {
      color: $white;
      background-color: $brand-blue;

      h3 {
        color: #fff;
      }

      .btn {
        background-color: $white;
      }

      .info {
        font-size: 14px;
      }

      .price-features {
        li {
          background-image: url(/assets/ic/component/check-circle-gray.svg);
        }
      }
    }
  }
}

.price-sales {
  display: block;
  margin-top: 40px;

  @include respond-to('desktop') {
    @include flex;
  }

  > * {
    + * {
      margin-top: 16px;

      @include respond-to('desktop') {
        margin-top: 0;
      }
    }
  }

  a {
    display: block;
    color: $brand-blue;
    font-weight: 700;
  }

  .info {
    @include flex-fill;
  }
}

.price-table-wrap {
  position: relative;
  @include size(100%, 100%);

  .gutter-box {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 100%;
    background-image: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0) 99%);

    @include respond-to('desktop') {
      opacity: 0;
    }
  }
}

.price-table {
  @include flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 40px;
  font-size: 14px;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  button {
    cursor: pointer;
  }

  .col {
    @include size(226px, auto);

    @include respond-to('desktop') {
      @include size(100%, auto);
    }

    &:first-child {
      flex: 30%;
      text-align: left;
    }

    &:nth-child(2) {
      flex: 35%;
    }

    &:nth-child(3) {
      flex: 35%;
    }

    &.hide {
      .table-divider {
        border-bottom: 1px solid $white;
      }

      .table-header {
        opacity: 0;
      }
    }
  }

  .col-group {
    @include flex;

    .col {
      &:first-child {
        text-align: center;
      }
    }
  }

  .table-divider {
    color: $brand-blue;
    border-bottom: 1px solid $brand-blue;
    padding-bottom: 6px;
  }

  .table-header {
    text-align: center;
    padding: 16px;
    min-width: 226px;
    min-height: 160px;

    > * {
      + * {
        margin-top: 16px;
      }
    }
  }

  .table-list {
    li {
      border-bottom: 1px solid $border-color;
      // @include size(226px, 48px);
      @include size(100%, 48px);
      line-height: 53px;

      @include respond-to('desktop') {
        @include size(100%, 48px);
      }

      &.title {
        font-size: $h3-font-size;
        font-weight: 700;
        margin-top: 28px;
        border-bottom: 1px solid $brand-gray;

        &:first-child {
          margin-top: 0;
        }
      }

      .multiline {
        vertical-align: middle;
        display: inline-block;
        line-height: 1.5;
      }

      &.h_3 {
        height: 64px;
      }
    }
  }
}
