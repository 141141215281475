// Brand list group
// - Default

// Default
.list-group {
  margin-top: 40px;

  @include respond-to('desktop') {
    @include flex;
  }

  .list-group-item {
    @include respond-to('desktop') {
      @include size(50%, auto);
      padding-right: 40px;
    }

    .icon-title-group {
      @include flex;

      img {
        @include size(50px);
        margin-right: 16px;
      }

      h3 {
        @include flex-fill;
        margin-top: auto;
        margin-bottom: auto;
      }

      div + h3 {
        padding-top: 2px;
        line-height: 1.6;
      }
    }

    .info {
      margin-top: 24px;
      color: $brand-gray;
    }

    + .list-group-item {
      margin-top: 40px;

      @include respond-to('desktop') {
        margin-top: 0;
        padding-right: 0;
        padding-left: 40px;
      }
    }
  }

  &.triple-list {
    .list-group-item {
      @include respond-to('desktop') {
        @include size(33%, auto);
        padding-right: 20px;
      }

      + .list-group-item {
        @include respond-to('desktop') {
          padding-left: 20px;
        }
      }

      &:last-child {
        @include respond-to('desktop') {
          padding-right: 0;
        }
      }
    }
  }

  // 메인에서 사용
  &.lg-list-group {
    .list-group-item {
      // 메인 제목영역
      h1 {
        line-height: 1.4;
      }

      .main-label {
        color: $brand-blue;
      }

      a {
        display: block;
        color: $brand-blue;
        margin-top: 16px;
      }

      .icon-title-group {
        img {
          @include size(70px);

          @include respond-to('desktop') {
            @include size(90px);
          }
        }

        h3 {
          width: 290px;
          color: $brand-gray;
        }
      }
    }
  }
}

// Blog list
.blog-list-group {
  display: block;
  margin-top: 20px;

  @include respond-to('desktop') {
    @include flex;
  }

  .list-group-item {
    @include size(100%);
    border-radius: 8px;

    + .list-group-item {
      margin-top: 24px;

      @include respond-to('desktop') {
        margin-left: 24px;
        margin-top: 0;
      }
    }

    a {
      display: block;
      @include size(100%);
    }

    .image {
      border-radius: 8px 8px 0 0;
      @include size(100%, 200px);
      @include background-img(cover, center);
      background-image: url(/assets/img/home/article-2-min.png);

      &.life-wire {
        background-image: url(/assets/img/home/article-1-min.png);
      }
    }

    .data {
      padding: 16px;
      border-radius: 0 0 8px 8px;
      color: $brand-gray;
      border: 1px solid $border-color;
      border-top: none;
      min-height: 150px;

      .info {
        margin-top: 4px;
        color: $brand-gray-light;
      }

      .label {
        color: $brand-blue;
        margin-top: 10px;
      }
    }
  }

  &.sm-list-group {
    .list-group-item {
      width: 100%;

      @include respond-to('desktop') {
        width: 33%;
      }

      .data {
        @include flex;
        padding: 8px 12px;
        border: 1px solid $border-color;
        border-radius: 8px;
        min-height: unset;
        // font-weight: 700;

        .icon {
          @include size(40px);
          margin-right: 8px;

          img {
            @include size(100% auto);
          }
        }

        .label {
          font-weight: 400;
          font-size: $caption-font-size;
          margin-top: 4px;
        }
      }
    }
  }
}
