// Flex

@mixin flex {
  display: -webkit-flex;
  display: flex;
}

@mixin flex-fill() {
  //비어있는 너비를 채움
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}