// Products
// - Features-accordion-group
// - Deco Item

// Features-accordion-group
.features-accordion-group {
  @include size(100%);
  color: $brand-gray;
  @include flex; // ie10 이하 버전 지원
  display: grid;
  grid-template-columns: repeat(1, 2fr);

  @include respond-to('desktop') {
    margin-top: 40px;
    grid-template-columns: repeat(2, 1fr);
  }

  .row {
    @include size(100%);
    font-size: $font-size-base;

    @include respond-to('desktop') {
      font-size: $block-sub-font-size;
    }

    &.image {
      position: relative;
      @include size(100%, 480px);
      margin: auto;
      background-color: $brand-blue-100;
      //border: 1px solid $brand-blue-100;
      border-radius: 16px;
      order: 1;

      @include respond-to('desktop') {
        //max-width: 378px;
        //margin-right: 100px;
        @include size(378px, 450px);
        margin-left: auto;
        margin-right: 0;
        background-color: transparent;
        order: 2;
      }

      img {
        position: absolute;
        @include size(100%, auto);
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;

        @include respond-to('mobile') {
          max-width: 300px;
        }

        @include respond-to('tablet') {
          max-width: 378px;
        }
      }
    }

    &.data {
      order: 2;

      @include respond-to('desktop') {
        order: 1;
      }

      h2 {
        margin-top: 40px;
        margin-bottom: 20px;
        color: $brand-black;

        @include respond-to('desktop') {
          margin-top: 0;
        }
      }

      .accordion-action {
        margin-top: 40px;

        .ac-action {
          font-size: $h3-font-size;
        }

        .panel {
          font-size: $font-size-base;
        }
      }

      @include size(100%, auto);
      margin: auto;

      @include respond-to('desktop') {
        //max-width: 410px; //아코디언 내용 넓게 보여주기
        @include size(390px, auto);
      }
    }
  }

  &.reverse-order {
    .row {
      &.data {
        order: 2;

        @include respond-to('desktop') {
          //max-width: 410px; //아코디언 내용 넓게 보여주기
          @include size(410px, auto);

          .ac-action {
            padding-right: 2px;
          }
        }
      }

      &.image {
        order: 1;

        @include respond-to('desktop') {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
}

// Deco Item

.jumbotron {
  &.coloum {
    .col {
      &.image {
        // Calendar
        .cal-deco-1 {
          @include size(167px, auto);
          margin: unset;
          bottom: -15px;
          top: unset;
          left: unset;
          right: -8px;
        }

        // Contacts
        .con-deco-1 {
          @include size(80px, auto);
          margin: unset;
          bottom: unset;
          top: 2px;
          left: unset;
          right: 70px;
        }

        .con-deco-2 {
          @include size(230px, auto);
          margin: unset;
          bottom: 20px;
          top: unset;
          left: unset;
          right: 1px;
        }

        // Tasks & Notes
        .tas-deco-1 {
          @include size(100px, auto);
          margin: unset;
          bottom: unset;
          top: -10px;
          left: unset;
          right: 80px;
        }

        .tas-deco-2 {
          @include size(200px, auto);
          margin: unset;
          bottom: -40px;
          top: unset;
          left: unset;
          right: 110px;
        }

        // Mail
        .mail-deco-1 {
          @include size(110px, auto);
          margin: unset;
          bottom: unset;
          top: 0;
          left: 32px;
          right: unset;
        }

        .mail-deco-2 {
          @include size(90px, auto);
          margin: unset;
          bottom: 16px;
          top: unset;
          left: unset;
          right: 24px;
        }
      }
    }
  }
}

.container.calendar {
  h1 {
    width: 370px;
    max-width: 100%;
  }

  h1 + div {
    width: 400px;
    max-width: 100%;
  }
}

.container.contact {
  h1 + div {
    width: 420px;
    max-width: 100%;
  }
}

.container.note {
  h1 {
    width: 380px;
    max-width: 100%;
  }

  h1 + div {
    width: 430px;
    max-width: 100%;
  }

  .image img {
    margin-top: -14px;
  }
}
