// Menu
// - main-menu
.main-menu {
  background-color: $white;
  border-radius: 8px;
  padding: 20px;

  &.menu-sm {
    position: absolute;
    top: 54px;
    z-index: $z-index-header-menu;
    font-size: 15px;
    box-shadow: 0px 3px 3px rgba(66, 66, 66, 0.15);
  }

  .munu-group {
    color: $brand-gray;

    .menu-item {
      a {
        display: block;
        padding: 8px;
        border-radius: 8px;

        &:hover {
          background-color: $brand-blue-100;
        }

        img + span {
          display: inline-block;
          padding-top: 2px;
          height: 22px;
        }
      }

      img {
        @include size(24px);
        margin-right: 12px;
        vertical-align: top;
      }

      &.divider {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid $brand-blue-100;
      }

      + .menu-item {
        margin-top: 8px;
      }
    }
  }
}
