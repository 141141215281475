// Global Color system

$white: #ffffff !default;
$gray-100: #EBEBEB !default;
$gray-200: #DADADA !default;
$gray-300: #A0A0A0 !default;
$gray-400: #7C7C7C !default;
$gray-500: #555555 !default;
$black: #25282B !default;

$red-100: #FDEFEF !default;
$red-200: #F4BDBC !default;
$red: #CF5F5B !default;

$yello-100: #FFFDF7 !default;
$yello-200: #FBE0B2 !default;
$yellow: #FBB329 !default;
$yellow-dark: #BC810E !default;

$green-100: #EEF9F4 !default;
$green-200: #BAE9CD !default;
$green: #48AC6C !default;

$blue-100: #DFE7FA !default;
$blue-200: #A7C6FA !default;
$blue: #4385F3 !default;

$violet-200: #B7BAEA !default;
$violet: #5755D4 !default;

$success: $green !default;
$info: $blue !default;
$warning: $yellow-dark !default;
$danger: $red !default;

// Brand Color System

$brand-blue: #496EE2;
$brand-blue-400: #93AEEE;
$brand-blue-100: #EAF0FF;

$brand-light-blue: #4370E7;

$brand-green: #61D4B0;

$brand-black: #0E1621;
$brand-gray: #3A4555;
$brand-gray-light: #9FA7C1;

$border-color: #D1D4E0;

$primary: $brand-blue;
$secondary: $brand-green;