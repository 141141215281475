@import 'components/mixins.scss';

@import 'scss/mixins';

.mainPopup {
  // max-width: 1420px;
  max-width: 1000px;
  min-height: 50vh !important;
  height: 90vh !important;
  overflow-y: hidden;

  .frame {
    position: absolute;
    width: 100%;
    height: calc(100% + 1px);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
  }

  @include respond-to('tablet') {
    width: 100%;
    height: 100% !important;
    max-height: 100%;
    border-radius: 0;
  }

  .close {
    display: none;
    position: absolute;
    right: 0;
    padding: 4px;
    border: 1px solid lightgray;
    // background: #fff;

    @include respond-to('tablet') {
      display: block;
      width: 50px;
      height: 50px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
