// Breakpoint
// mobile :  ~ 320px 디바이스
// tablet : 321px 이상 ~ 899px 이하 디바이스
// desktop : 700px 이상 디바이스

$breakpoints: (
  'mobile': (
    // max-width: 600px,
    max-width: $md-max-width,
  ),
  'tablet': (
    // min-width: 600px,
    min-width: $sm-min-width,
  )
  and
  (
    // max-width: 900px,
    max-width: $md-max-width,
  ),
  'desktop': (
    // min-width: 900px,
    min-width: $md-min-width,
  ),
);

// Components

$border-radius: 4px;
$border-radius-md: 8px;
$border-radius-lg: 16px;

// Z-index

$z-index-header: 200;
$z-index-main-deco: 201;
$z-index-header-link: 202;
$z-index-header-menu: 210;
$z-index-left-mobile-nav: 220;
$z-index-modal: 300;
