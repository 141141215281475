// Font family

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 400;
  src: url('/assets/font/NotoSansKR-Regular.otf');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 500;
  src: url('/assets/font/NotoSansKR-Medium.otf');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 700;
  src: url('/assets/font/NotoSansKR-Bold.otf');
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  src: url('/assets/font/Lato-Regular.ttf');
}

@font-face {
  font-family: Lato;
  font-weight: 700;
  src: url('/assets/font/Lato-Bold.ttf');
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  src: url('/assets/font/Lato-Italic.ttf');
}

@font-face {
  font-family: AvenirNext;
  font-weight: 400;
  src: url('/assets/font/AvenirNextLTPro-Regular.otf');
}

@font-face {
  font-family: AvenirNext;
  font-weight: 700;
  src: url('/assets/font/AvenirNextLTPro-Bold.otf');
}

@font-face {
  font-family: AvenirNext;
  font-style: italic;
  font-weight: 400;
  src: url('/assets/font/AvenirNextLTPro-It.otf');
}

// Brand Typography

$h1-font-size: 40px; // 페이지 타이틀
$h2-font-size: 32px; // 블록 타이틀
$h3-font-size: 18px; // 리스트 타이틀

$block-sub-font-size: 20px; // 블록 설명 타이틀

$font-size-base: 16px; // 리스트 설명 타이틀, 액션
$font-size-small: 12px;
$caption-font-size: 14px;

// Brand Typography Set Up

h1,
h2,
h3 {
  font-weight: 700;
}

h1 {
  color: #0e1621;
  font-size: $h2-font-size;
  line-height: 1.4;

  @include respond-to('desktop') {
    font-size: $h1-font-size;
  }
}

h2 {
  font-size: $h2-font-size;
  color: #0e1621;

  @include respond-to('desktop') {
    font-size: $h2-font-size;
    line-height: 1.4;
  }
}

h3 {
  font-size: $h3-font-size;
  color: #0e1621;
}
