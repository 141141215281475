// Forms
//
// * Add appereance

.form-label {
  color: $gray-500;
  font-weight: 500;
  display: block;
  font-size: $font-size-base;
}

.form-control {
  @include size(100%, 48px);

  @include respond-to('desktop') {
    @include size(100%, 56px);
  }

  border-radius: $border-radius;
  border: 1px solid $border-color;
  color: $gray-500;
  padding: 0 12px;
  font-size: $font-size-base;

  &:focus {
    border: 1px solid $primary;
  }

  &.with-icon {
    @include background-img(24px 24px, 12px 14px);
    padding-left: 44px;
  }
}

.form-hint {
  display: block;
  color: $gray-400;
  font-size: $caption-font-size;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  &.form-control {
    appearance: none; // Fix appearance for date inputs in Safari
  }
}

textarea {
  &.form-control {
    height: 60px;
    padding: 12px;

    @include respond-to('desktop') {
      height: 100px;
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;

  @include background-img(24px 24px, 100% 15px);
  background-image: url(/assets/ic/component/arrow_down_gray.svg);

  &:focus {
    color: $primary;
    border: 1px solid $primary;
    background-image: url(/assets/ic/component/arrow_down_blue.svg);
  }

  &.with-icon-select {
    background-size: 24px 24px,
      24px 24px;
    background-repeat: no-repeat,
      no-repeat;
    background-position: 100% 15px,
      12px 15px;
    background-image: url(/assets/ic/component/arrow_down_gray.svg),
      url(/assets/ic/component/user_gray.svg);
    padding-left: 44px;

    &:focus {
      background-image: url(/assets/ic/component/arrow_down_blue.svg),
        url(/assets/ic/component/user_blue.svg);
    }
  }
}

// Forms icon
.bg-icon-user {
  background-image: url(/assets/ic/component/user_gray.svg);

  &:focus {
    background-image: url(/assets/ic/component/user_blue.svg);
  }
}