@import 'components/mixins.scss';

@import 'scss/mixins';

.loginPopup {
  position: absolute;
  max-width: 730px;
  width: 60%;
  min-width: 430px;
  min-height: min(400px, 100vh);
  // min-height: 30vh !important;
  // height: 50vh !important;
  overflow-y: auto;
  margin: auto;

  :global(.modal-wrap) {
    position: relative;
    max-width: unset;
    max-height: unset;
    width: calc(100% - 20px);
    // height: calc(100% - 20px);
    padding-bottom: 60px;

    :global(.modal-header) {
      position: relative;
      max-width: 100%;
      width: 100%;
      min-height: 48px;
    }

    :global(.modal-content) {
      position: relative;
      padding: 0;

      @include respond-to('mobile') {
        padding: 0 20px;
      }
    }

    @include respond-to('mobile') {
      margin: 0 0;
      width: 100%;
    }
  }

  :global(.section) {
    padding: 0;
  }

  @include respond-to('mobile') {
    width: 100%;
    min-width: 300px;
    // height: 30% !important;
    max-height: 100%;
    border-radius: 10px;
  }
}
