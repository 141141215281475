// Brand Grid

$brand-max-width: 1024px;

// Grobal Grid
.container {
  @include size(100%);

  .section {
    &:last-child {
      padding-bottom: 80px;

      @include respond-to('desktop') {
        padding-bottom: 80px;
      }
    }

    &.no-padding-top {
      padding-top: 0;

      @include respond-to('desktop') {
        padding-top: 0;
      }
    }

    &.margin-top-80 {
      margin-top: 80px;
    }
  }

  &.depth-1 {
    @include respond-to('desktop') {
      .section {
        padding-left: 61.5px;
        padding-right: 61.5px;
      }
    }
  }

  &.depth-2 {
    @include respond-to('desktop') {
      .section {
        padding-left: 28px;
        padding-right: 28px;
      }
    }
  }
}

.section,
section {
  max-width: $brand-max-width;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 24px;

  @include respond-to('tablet') {
    padding: 80px 24px;
  }

  &.center {
    text-align: center;

    .t-p1 {
      color: $brand-blue;
    }
  }
}

.section {
  &.col-2 {
    @include respond-to('desktop') {
      @include flex;
    }

    .col {
      &.image {
        position: relative;
        @include size(100%, 300px);
        max-width: 463px;
        margin-right: 0;

        @include respond-to('desktop') {
          @include size(100%, 458px);
          margin-right: 40px;
        }

        img {
          position: absolute;
          @include size(100%, auto);
          max-width: 430px;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
        }
      }

      &.data {
        @include flex-fill;
        margin: auto;

        h2 {
          margin-top: 40px;

          @include respond-to('desktop') {
            margin-top: 0;
          }
        }

        .info {
          margin-top: 32px;
          color: $brand-gray;
          font-size: $block-sub-font-size;
        }
      }
    }
  }

  .row.data {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;

    .main-label {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.2;
      color: #496ee2;
    }

    h2 {
      font-size: $h2-font-size;

      @include respond-to('desktop') {
        font-size: $h2-font-size;
        font-weight: bold;
        line-height: 1.4;
        color: #3a4555;
      }
    }

    h2 + div {
      font-size: 20px;
      font-weight: normal;
      line-height: 1.6;
      color: #3a4555;
    }

    .main-label + h2 + div {
      font-size: 16px;
    }
  }
}
