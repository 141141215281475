// Scroll animation

.fade-up {
  opacity: 0;
  transform: translateY(100px);
  transition: transform 4s .25s cubic-bezier(0, 1, .3, 1),
    opacity .3s .25s ease-out;
  will-change: transform, opacity;
}

.fade-up.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.fade-left {
  opacity: 0.4;
  transform: translateX(100px);
  transition: transform 4s .25s cubic-bezier(0, 1, .3, 1),
    opacity .3s .25s ease-out;
  will-change: transform, opacity;
}

.fade-left.is-visible {
  opacity: 1;
  transform: translateX(0);
}