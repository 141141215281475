// Form group
//
// Base style
// * Add sizing

.form-group {
  @include size(100%, auto);

  >*+* {
    margin-top: 12px;
  }

  +.form-group {
    margin-top: 20px;
  }
}

// Inline
.inline-form-group {
  @include flex;

  >*+* {
    margin-top: 0;
    margin-left: 8px;
  }

  .form-label {
    width: 120px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

// Sizing
.form-group-sm {
  .form-control {
    @include size(100%, 40px);

    &.with-icon {
      @include background-img(24px 24px, 12px 6px);
      padding-left: 44px;
    }
  }

  select {
    &.form-control {
      @include background-img(24px 24px, 100% 7px);
    }
  }
}

// login-form-group
.login-form-group {
  margin-top: 32px;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    margin-top: 40px;
    margin-bottom: 32px;
  }

  .btn {
    margin-top: 32px;
  }
}