@import 'components/mixins.scss';

li:global(.menu-item.no-focus) {
  pointer-events: none;
}

:global(.navbar-brand .use-avatar) {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;

  .accountItem {
    position: relative;
    width: 100%;
    height: 100%;

    .avatar {
      // float: left;
      width: 100%;
      height: 100%;
      // text-align: center;

      > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > span:global(.fe) {
        font-size: 30px;
        width: 100%;
        height: 100%;

        &:before {
          position: relative;
          top: 5px;
          left: 0;
        }
      }
    }
  }
}
