// Grobal footer

.footer {
  @include size(100%, auto);
  // background-color: $brand-black;
  // color: $white;
  background-color: $white;
  color: #3a4555;
  font-size: 13px;

  .container {
    max-width: $brand-max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;

    @include respond-to('desktop') {
      padding: 50px 24px 35px 24px;
    }

    .row {
      display: block;

      @include respond-to('desktop') {
        @include flex;
      }

      + .row {
        margin-top: 40px;
      }

      .logo {
        @include flex-fill;
        margin-bottom: 24px;

        @include respond-to('desktop') {
          margin-bottom: 0;
        }

        img {
          @include size(48px, auto);

          @include respond-to('desktop') {
            @include size(76px, auto);
          }
        }
      }

      .menu-link {
        display: block;

        @include respond-to('desktop') {
          @include flex;
        }

        ul {
          + ul {
            margin-top: 24px;

            @include respond-to('desktop') {
              margin-top: 0;
              margin-left: 120px;
            }
          }

          li {
            + li {
              margin-top: 12px;
              height: 16px;
            }
          }
        }

        .label {
          font-size: $font-size-small;
          // color: $brand-gray-light;
          // opacity: 0.5;
          color: #9fa7c1;
          font-weight: 700;
          opacity: 1;
        }
      }

      .terms {
        @include flex-fill;
        align-self: flex-end;

        a {
          display: inline-block;
          padding-left: 20px;
          @include background-img(12px 12px, 4px 4px);
          background-image: url(/assets/ic/component/divider-dot-white.png);

          &:first-child {
            background-image: none;
            padding-left: 0;
          }
        }
      }

      .license {
        // color: $brand-gray-light;
        // opacity: 0.5;
        // font-weight: 700;
        color: #9fa7c1;
        font-weight: 700;
        opacity: 1;
        margin-top: 24px;
        align-self: flex-end;

        @include respond-to('desktop') {
          margin-top: 0;
        }

        img {
          vertical-align: baseline;
          @include size(88px, auto);
          margin-left: 16px;
          filter: grayscale(80%);
        }
      }
    }
  }
}
