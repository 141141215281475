// Button

.btn {
  @include size(auto, 56px);
  display: inline-block;
  padding: 15px 24px;
  border-radius: $border-radius;
  // font-size: $font-size-base;
  font-weight: 700;
  position: relative;
  vertical-align: middle;
  text-align: center;
  // font-family: 'Noto Sans KR';
  font-size: 16px;

  img {
    @include size(24px);
    margin-top: -4px;
    margin-right: 8px;
  }

  &.icon-only {
    padding: 15px;
  }

  &.btn-sm {
    @include size(auto, 40px);
    padding: 10px 12px;
    font-size: $caption-font-size;
  }

  &.btn-lg2 {
    @include size(auto, 56px);
    padding: 15px 32px;
  }

  &.btn-lg {
    @include size(auto, 64px);
    padding: 19px 32px;
  }

  &.btn-block {
    display: block;
    width: 100%;
  }
}

.btn-primary {
  color: $white;
  background-color: $primary;

  &:hover {
    color: $primary;
    background-color: $blue-200;
  }
}

.btn-default {
  background-color: $gray-200;
  color: $gray-400;

  &:hover {
    color: $gray-500;
    background-color: $gray-100;
  }
}

.btn-subtle {
  color: $gray-500;
  background-color: $white;

  &:hover {
    color: $primary;
    background-color: $white;
  }
}

.btn-outline {
  color: $primary;
  background-color: transparent;
  border: 1px solid $primary;

  &:hover {
    background-color: $blue-100;
  }
}

// Gradient Button
.gradient-box {
  border: double 2px transparent;
  border-radius: 4px;
  background-image: linear-gradient($white, $white),
    linear-gradient(to right, $brand-green, $brand-light-blue);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
