// Image

// Image fluid
@mixin img-fluid() {
  width: 100%; // 부모 너비의 100%를 채움
  height: auto; // 너비 100%에 따른 높이를 자동으로 채움
}

// Background image
// 
// background-image: url(/assets/ic/lnb-manage-term-gr.png);
@mixin background-img($bg-size, $bg-position) {
  background-size: $bg-size;
  background-position: $bg-position;
  background-repeat: no-repeat;
}