// Global Navigation

.navbar {
  position: inherit;
  @include size(100%, auto);
  transition-duration: 0.1s;

  &.is-scrolled {
    position: fixed;
    top: 0;
    transition-duration: 0.1s;
    z-index: $z-index-header;
  }
}

.left-mobile-nav {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  z-index: $z-index-left-mobile-nav;
  @include flex;
  align-items: flex-start;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  left: 0;
  bottom: 0;
  // height: 100vh;
  // width: 100%;
  background-color: $white;

  @include respond-to('desktop') {
    display: none;
  }

  .header {
    position: fixed;
    padding: 24px;
    @include flex;
    @include size(100%, auto);
    background-color: $white;

    .brand-logo {
      @include flex-fill;
      @include size(120px, auto);

      img {
        @include size(120px, auto);
      }
    }

    margin-bottom: 24px;

    .close {
      display: block;
      margin-top: 8px;

      img {
        font-size: $caption-font-size;
        @include size(24px);
        margin-top: -2px;
      }
    }
  }

  .inner-box {
    padding: 80px 24px 24px 24px;
    width: 100%;
    flex: 1;
  }

  .bottom-box {
    padding: 0 24px 24px 24px;
    width: 100%;
  }

  .sub-label {
    font-size: $h3-font-size;
    color: $brand-gray-light;
    margin-bottom: 4px;
  }

  .menu-link {
    display: block;
    font-size: $h3-font-size;
    color: $brand-gray;
    margin-top: 16px;
  }

  .main-menu {
    padding: 0;
  }

  .gradient-box {
    text-align: center;
    @include size(100%, 40px);
    line-height: 40px;
  }
}
