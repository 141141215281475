@import 'components/mixins.scss';

.formContainer {
  .email {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
  }
}

.errorMsg {
  display: none;
  color: red;
}

.prevEmail {
  margin-top: -25px;
  font-weight: normal;
  word-break: break-all;
}
