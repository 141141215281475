// Brand Header

.navbar {
  // Scroll event
  &.is-scrolled {
    background-color: $white;
    border-bottom: 1px solid $border-color;

    .navbar-brand {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

// Bg-Black
// .navbar-black {
.navbar {
  background-color: $white;
  // background-color: $brand-black;
  // color: $brand-black;
  font-family: 'AvenirNext', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #3a4555;

  .navbar-brand {
    .brand-logo {
      background-image: url(/assets/ic/brand_logo/rework-app-logo.png);
    }

    .brand-menu {
      font-size: 16px;
      // .menu-list {
      //   color: $white;
      // }

      // .dropdown-action {
      //   background-image: url(/assets/ic/component/arrow-down-line-white.svg);
      // }
    }

    .mobile-menu {
      font-size: 16px;
      // color: $white;
      background-image: url(/assets/ic/component/menu-white.svg);
    }

    .gradient-box {
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      // color: $white;
      // background-image: linear-gradient($brand-black, $brand-black),
      //   linear-gradient(to right, #61D4B0, #4370E7);
      background-image: linear-gradient($white, $white), linear-gradient(to right, #61d4b0, #4370e7);

      [role='button'] {
        padding-top: 1px;
      }
    }
  }

  &.is-scrolled {
    .navbar-brand {
      .brand-logo {
        background-image: url(/assets/ic/brand_logo/rework-app-logo.png);
      }

      .brand-menu {
        .menu-list {
          color: $brand-gray;
        }

        .dropdown-action {
          background-image: url(/assets/ic/component/arrow-down-line.svg);
        }
      }

      .mobile-menu {
        color: $brand-gray;
        background-image: url(/assets/ic/component/menu_gray.svg);
      }

      .gradient-box {
        background-image: linear-gradient($white, $white),
          linear-gradient(to right, #61d4b0, #4370e7);
        color: $brand-gray;
      }
    }
  }
}

// Bg-White
// .navbar-white {
//   background-color: $white;
// }

.navbar-brand {
  @include size(100%, auto);
  @include flex;
  max-width: $brand-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;

  @include respond-to('desktop') {
    padding-top: 40px;
  }

  // Logo
  .brand-logo {
    display: block;
    @include size(140px, 40px);
    @include background-img(60px 60px, 0 -10px);
    background-image: url(/assets/ic/brand_logo/rework-app-logo.png);
    padding-left: 65px;
    padding-top: 13px;
    font-size: 18px;
    font-family: AvenirNext;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #3a4555;

    @include respond-to('desktop') {
      @include size(198px, 56px);
      @include background-img(84px 84px, 0 -14px);
      padding-left: 96px;
      padding-top: 18px;
      font-size: 24px;
    }

    img {
      @include size(auto, 100%);
    }
  }

  // Menu
  .brand-menu {
    position: relative;
    @include flex-fill;
    padding-top: 16px;
    padding-right: 0;
    z-index: $z-index-header-link;

    @include respond-to('desktop') {
      padding-right: 65px;
    }

    .menu-list {
      display: none;
      float: right;

      @include respond-to('desktop') {
        @include flex;
      }

      > li {
        + li {
          margin-left: 50px;
        }

        img {
          @include size(24px);
        }
      }

      a {
        display: block;
      }
    }

    .dropdown-action {
      cursor: pointer;
      padding-right: 32px;
      @include background-img(24px 24px, 100% 0);
      background-image: url(/assets/ic/component/arrow-down-line.svg);
    }
  }

  // Mobile Menu
  .mobile-menu {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    font-size: $caption-font-size;
    @include background-img(24px 24px, 0 -2px);
    background-image: url(/assets/ic/component/menu_gray.svg);
    padding-left: 32px;

    @include respond-to('desktop') {
      display: none;
    }

    img {
      margin-top: -4px;
      margin-right: 8px;
    }
  }

  // Button
  .gradient-box {
    display: none;
    @include size(106px, 46px);
    line-height: 43px;
    margin-top: 5px;
    font-size: $caption-font-size;
    color: $brand-gray;
    text-align: center;
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }

    @include respond-to('desktop') {
      display: block;
    }

    a {
      display: block;
      @include size(100%);
    }
  }
}
